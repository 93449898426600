import React from 'react';
import { Heros } from '../page/heropage';
import { Bagian } from '../page/footerpage';
import { Problem } from '../page/problempage';
import { Client } from '../page/clientpage';
import { Platform } from '../page/platform';
import { Platforms } from '../page/platforms';

export const Hero = () => {
    return (
        <>
            <Heros />
            <Problem />
            <Platform />
            <Platforms />
            <Client />
            <Bagian />
        </>
    )
}