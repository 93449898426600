import React, { useState, useEffect } from 'react';
import client1 from "../../asset/client/Partner1.jpeg";
import client2 from "../../asset/client/Partner2.png";
import client3 from "../../asset/client/Partner3.png";
import client4 from "../../asset/client/Partner4.jpg";
import client5 from "../../asset/client/Partner5.png";
import client6 from "../../asset/client/Partner6.jpg";
import client7 from "../../asset/client/Partner7.png";
import client8 from "../../asset/client/Partner8.png";
import client9 from "../../asset/client/Partner9.jpg";
import client10 from "../../asset/client/Partner10.jpg";
import bgp from "../../asset/img/splash/wrkpln81.png";

const gambar = [
    client1,
    client2,
    client3,
    client4,
    client5,
    client6,
    client7,
    client8,
    client9,
    client10
];

export const Client = () => {
    const [currentGambar, setCurrentGambar] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentGambar(currentGambar => currentGambar - 1);
        }, 45);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const slider = document.getElementById('gambar-slider');
        const firstGambar = slider.children[0];
        const gambarWidth = firstGambar.offsetWidth;

        if (currentGambar <= -gambarWidth) {
            setCurrentGambar(currentGambar + gambarWidth);
            slider.appendChild(firstGambar);
        }
        slider.style.transform = `translateX(${currentGambar}px)`;
    }, [currentGambar]);


    return (
        <>
            <div className="relative border-gray-600">
                <div className="bg-repeat-space">
                    <div className="py-5 p-2">
                        <h3 className="text-center font-Nunito lg:text-4xl text-2xl mb-6"
                            style={{ color: '#333333' }}
                            data-aos="zoom-in">Trusted by Partners</h3>
                        <hr data-aos="zoom-in" className="h-1 rounded my-4 mx-auto lg:w-96 w-64 bg-gray-400 border-0"></hr>
                    </div>
                    <div className="relative flex">
                        <div id='gambar-slider'
                            className="flex overflow-hidden items-center mx-3 gap-3 ease-in-out"
                            style={{ width: "100%" }}
                            loading='lazy'>
                            {gambar.map((image, index) => (
                                <div key={index} className="w-full hover:grayscale-0 grayscale">
                                    <img src={image} alt="clients" style={{ width: "65%", aspectRatio: "2/3", objectFit: "contain", alignContent: "stretch" }} />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div data-aos="zoom-in-down"
                        data-aos-duration="1500"
                        data-aos-easing="ease-in-sine"
                        className="w-full h-full bg-cover"
                        style={{ backgroundImage: `url(${bgp})` }}
                        loading='lazy'>
                        <div className="pl-2">
                            <h3 className="lg:text-4xl lg:py-40 py-24 text-xl font-semibold leading-normal" style={{ color: "#fd7e14" }}>One Application <br /> to Your Bussiness</h3>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
