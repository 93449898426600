import React from 'react';
import { Company } from '../page/companypage';
import { Bagian } from '../page/footerpage';

export const CompanyPage = () => {
    return (
        <>
            <Company />
            <Bagian />
        </>
    )
}