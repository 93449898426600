import React from 'react';
import { Produk } from '../page/productpage';
import { Bagian } from '../page/footerpage';

export const ProdukPage = () => {
    return (
        <>
            <Produk />
            <Bagian />
        </>
    )
}