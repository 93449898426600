import React from "react";
import plt from "../../asset/img/bg/Phone.png";


export const Platform = () => {
    const Text1 = {
        color: '#4d4d4d',
    }
    const text2 = {
        color: '#44cc77',
    }

    return (
        <div className="relative">
            <div className="mx-4 my-4">
                <div className="w-full h-full bg-white">
                    <div className="lg:my-5 my-2">
                        <h3 className="text-center font-Nunito lg:text-4xl text-2xl mb-6"
                            style={{ color: '#333333' }}
                            data-aos="zoom-in">Solutions</h3>
                        <hr data-aos="zoom-in" className="h-1 rounded my-4 mx-auto lg:w-60 w-44 bg-gray-400 border-0"></hr>
                    </div>
                    <div className="container justify-items-center">
                        <div className="flex flex-col font-Roboto text-center">
                            <div className="my-4 leading-relaxed">
                                <h5 data-aos="zoom-in-up" data-aos-duration="1500" className="lg:text-xl text-base font-semibold" style={text2}>Human Resource System</h5>
                                <p data-aos="zoom-in-up" data-aos-duration="2000" className="mt-3 lg:text-base text-xs" style={Text1}>Attendance, Overtime &amp; Leave Application</p>
                            </div>
                            <div className="container items-center">
                                <div className="lg:grid-cols-4 sm:grid">
                                    <div className="my-4 leading-relaxed">
                                        <div className="lg:mt-14 pt-2 lg:pl-4">
                                            <h5 className="lg:text-xl text-base font-semibold" style={text2}>Task Management</h5>
                                            <p className="mt-3 lg:text-base text-xs" style={Text1}>Task Distribution, Team Productivity Control, Daily Visit &amp; Customer Data</p>
                                        </div>
                                    </div>
                                    <div data-aos="zoom-in" data-aos-duration="1500" className="flex justify-center my-5 md:my-0 col-span-2">
                                        <img
                                            src={plt}
                                            alt="Productivity Monitoring Platform"
                                            width='611'
                                            height='764'
                                            loading='lazy'
                                            className="lg:w-2/5 w-5/12 translate-x-1 drop-shadow-[10px_-10_0_rgba(250,204,21,1)]"
                                        />
                                    </div>
                                    <div className="my-4 leading-relaxed">
                                        <div className="lg:mt-14 pt-2 lg:pr-4">
                                            <h5 className="lg:text-xl text-base font-semibold" style={text2}>Payroll System</h5>
                                            <p className="mt-3 lg:text-base text-xs" style={Text1}>Financial Report, Payroll Management &amp; Cost Management </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="my-4 leading-relaxed">
                                <h5 data-aos="zoom-in-down" data-aos-duration="1500" className="lg:text-xl text-base font-semibold" style={text2}>Support System</h5>
                                <p data-aos="zoom-in-down" data-aos-duration="2000" className="mt-3 lg:text-base text-xs" style={Text1}>Survey Management, Asset Management &amp; GPS Tracking</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <svg
                className="relative bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
            >
                <polygon
                    className="fill-current"
                    style={{ color: "#ebf1f1" }}
                    points="2560 0 2560 100 0 100"
                ></polygon>
            </svg>
        </div>
    )
}