import React from "react";
import task from "../../asset/img/splash/wrkpln7.png";
import hcm from "../../asset/img/splash/wrkpln5.png";
import payroll from "../../asset/img/splash/wrkpln3.png";
import support from "../../asset/img/splash/wrkpln6.png";
import produk from "../../asset/img/bg/produk.png";

export const Produk = () => {
    const Rl = {
        color: '#28a745',
    }
    const Uli = {
        color: "#4d4d4d",
    }
    window.scrollTo(0, 0);

    return (
        <>
            <div className="w-full h-full">
                <div className="relative top-0 py-24 bg-center bg-cover" style={{ backgroundImage: `url(${produk})` }}>
                    <div className="flex text-center md:px-8 px-4 flex-col font-Roboto justify-between"
                        data-aos="flip-left"
                        data-aos-duration="2000">
                        <h2 className="md:text-4xl text-2xl leading-relaxed" style={{ color: "#28a745" }}>Product &amp; Service</h2>
                        <p className="mt-3 text-base md:mx-8 md:text-lg leading-relaxed" style={{ color: "#d8d8d8" }}>Wrkpln provides products and services as a solution to the needs of your business and employees to grow together. We have helped many businesses and UKM in Indonesia with a safe and comfortable human resource system.<br />
                            WrkPln is backed by a solid architecture that allows for a variety of customizations over our default version to meet the specific needs of your organization. Our professional service team is ready to assist you in the personalization process, from needs analysis to ready-to-use.</p>
                    </div>
                    <svg
                        className="absolute bottom-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="text-white fill-current"
                            points="2560 0 2560 100 0 100"
                        ></polygon>
                    </svg>
                </div>
                <div className="flex flex-wrap lg:pt-24 pt-14">
                    <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                        <div className="relative flex flex-col min-w-0 break-words items-center w-full mt-2 mb-3"
                            data-aos="fade-right"
                            data-aos-duration="2000">
                            <img alt="WrkPln" src={task} loading="lazy" className="lg:w-full w-9/12 rounded-tr-3xl rounded-bl-3xl align-middle" />
                        </div>
                    </div>
                    <div className="col-auto w-full md:w-5/12 text-center pt-4 px-4 mr-auto ml-auto"
                        data-aos="fade-down"
                        data-aos-duration="2000">
                        <h2 className="lg:text-4xl text-2xl font-Roboto lg:text-left leading-relaxed mt-2 mb-5" style={Rl}>Task Management </h2>
                        <p className="lg:text-left lg:text-lg text-sm leading-normal mb-4 font-Roboto" style={Uli}>WrkPln integrates a Project Task Management to help your company control, Manage every moment from anywhere and anytime</p>
                        <ul className="pl-4 lg:text-lg text-sm">
                            <ul className="grid grid-cols-2 justify-between gap-1 font-Roboto text-left list-disc" style={Uli}>
                                <li>Dashboard Progress</li>
                                <li>Task Management</li>
                                <li>Project Calender</li>
                                <li>Visit data Collection</li>
                                <li>Daily analysis on team productivity</li>
                                <li>Visit Scheduling &amp; Tracking</li>
                                <li>Geotagging Check in &amp; Check out</li>
                            </ul>
                        </ul>
                    </div>
                </div>
                <svg
                    className="relative bottom-0 overflow-hidden"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none"
                    version="1.1"
                    viewBox="0 0 2560 100"
                    x="0"
                    y="0"
                >
                    <polygon
                        className="fill-current"
                        style={{ color: "#f8f9fa" }}
                        points="2560 0 2560 100 0 100"
                    ></polygon>
                </svg>
                <div className="w-full h-full" style={{ backgroundColor: "#f8f9fa" }}>
                    <div className="flex flex-wrap-reverse lg:py-10 py-5">
                        <div className="col-auto w-full md:w-5/12 md:pt-20 text-center px-4 mr-auto ml-auto"
                            data-aos="fade-down"
                            data-aos-duration="2000">
                            <h2 className="lg:text-4xl text-2xl font-sans lg:text-left leading-relaxed mt-2 mb-5" style={Rl}>Human Resource System</h2>
                            <p className="lg:text-left lg:text-lg text-sm leading-normal mb-4 font-sans" style={Uli}>Experience hassle-free HR management with WrkPLN today!<br />
                                Our fully integrated system automation simplifies all your HR tasks in one easy-to-use dashboard & Say goodbye to more time-consuming manual processes for your business activities.</p>
                            <ul className="pl-4 lg:text-lg text-sm">
                                <ul className="grid grid-cols-2 justify-between text-left list-disc" style={Uli}>
                                    <li>Attandance </li>
                                    <li>Personal Information</li>
                                    <li>Approval Management</li>
                                    <li>Organization Structure</li>
                                    <li>User Role Access</li>
                                    <li>Admin Dashboard</li>
                                    <li>Leave &amp; Overtime Management</li>
                                </ul>
                            </ul>
                        </div>
                        <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                            <div className="relative flex flex-col min-w-0 break-words items-center w-full mt-2 mb-3"
                                data-aos="zoom-in-up"
                                data-aos-duration="2000">
                                <img alt="WrkPln" src={hcm} loading="lazy" className="lg:w-full w-9/12 align-middle rounded-br-3xl rounded-tl-3xl" />
                            </div>
                        </div>
                    </div>
                    <svg
                        className="relative bottom-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="fill-current text-green-100"
                            points="2560 0 2560 100 0 100"
                        ></polygon>
                    </svg>
                </div>
                <div className="w-full h-full bg-green-100">
                    <div className="flex flex-wrap lg:py-10 py-5">
                        <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                            <div className="relative flex flex-col min-w-0 break-words items-center w-full mt-2 mb-3"
                                data-aos="zoom-in"
                                data-aos-duration="2000">
                                <img alt="WrkPln" src={payroll} loading="lazy" className="lg:w-full w-9/12 align-middle rounded-bl-3xl rounded-tr-3xl" />
                            </div>
                        </div>
                        <div className="col-auto w-full md:w-5/12 text-center px-4 md:pt-8 mr-auto ml-auto"
                            data-aos="fade-down"
                            data-aos-duration="2000">
                            <h2 className="lg:text-4xl text-2xl font-Roboto lg:text-left leading-relaxed mt-2 mb-5" style={Rl}>Payroll System</h2>
                            <p className="lg:text-left lg:text-lg text-sm leading-normal mb-4 font-Roboto" style={Uli}>Calculating employee salaries efficiently and as needed The payroll process is faster and easier with the calculation of automatic payroll components such as PPh 21, Benefits, BPJS, Loan repayments, and many more.</p>
                            <ul className="pl-4 lg:text-lg text-sm">
                                <ul className="grid grid-cols-2 justify-between gap-1 font-Roboto text-left list-disc" style={Uli}>
                                    <li>Salary Calculation</li>
                                    <li>Overtime Pay Calculation</li>
                                    <li>Benefits Calculation</li>
                                    <li>PPh 21 tax &amp; BPJS Calculation</li>
                                    <li>Loan &amp; Reimbursement Calculation</li>
                                </ul>
                            </ul>
                        </div>
                    </div>
                    <svg
                        className="relative bottom-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="fill-current text-light-green-100"
                            points="2560 0 2560 100 0 100"
                        ></polygon>
                    </svg>
                </div>
                <div className="w-full h-full bg-light-green-100">
                    <div className="flex flex-wrap-reverse lg:py-10 py-5">
                        <div className="col-auto w-full md:w-5/12 md:pt-20 text-center px-4 mr-auto ml-auto"
                            data-aos="fade-down"
                            data-aos-duration="2000">
                            <h2 className="lg:text-4xl text-2xl font-sans lg:text-left leading-relaxed mt-2 mb-5" style={Rl}>Supporting System</h2>
                            <p className="lg:text-left lg:text-lg text-sm leading-normal mb-4 font-sans" style={Uli}>WrkPln provides supporting features to help Companies and Users develop Human Reasources with a System Easy and Secure.</p>
                            <ul className="pl-4 lg:text-lg text-sm">
                                <ul className="grid grid-cols-2 justify-between text-left list-disc" style={Uli}>
                                    <li>Blast Message</li>
                                    <li>Asset Management</li>
                                    <li>Pin Security</li>
                                    <li>Survey Employee</li>
                                    <li>Office entry Quota</li>
                                    <li>Real Time System</li>
                                    <li>GPS Tracking</li>
                                </ul>
                            </ul>
                        </div>
                        <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                            <div className="relative flex flex-col min-w-0 break-words items-center w-full mt-2 mb-3"
                                data-aos="fade-right"
                                data-aos-duration="2000">
                                <img alt="WrkPln" src={support} loading="lazy" className="lg:w-full w-9/12 align-middle rounded-br-3xl rounded-tl-3xl" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}