import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Hero } from "./components/control/hero";
import { KontakPage } from "./components/control/contact";
import { Navbar } from "./components/control/navbar";
import { TermsPage } from "./components/control/terms";
import { CompanyPage } from "./components/control/company";
import { ProdukPage } from "./components/control/services";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  AOS.init({
    duration: 800, // durasi animasi dalam milidetik
    easing: "ease-in-out", // jenis easing yang digunakan dalam animasi
    delay: 100, // jeda waktu sebelum animasi dimulai
    // offset: 200, // jarak elemen dari tepi layar sebelum di-animasikan
    once: true, // elemen hanya di-animasikan sekali saat pertama kali di-scroll ke dalam tampilan
  });
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Hero />} />
        <Route path="/Produk" element={<ProdukPage />} />
        <Route path="/kontak" element={<KontakPage />} />
        <Route path="/Terms" element={<TermsPage />} />
        <Route path="/Company" element={<CompanyPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
