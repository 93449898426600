import React, { useState } from 'react';
import { useEffect } from "react";
import logo from "../../asset/img/bg/logo.png";
import { Link } from 'react-router-dom';

export const Navbar = () => {
    const [navbarBuka, setNavbarBuka] = React.useState(false);
    const [sticky, setSticky] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            window.scrollY > 1 ? setSticky(true) : setSticky(false);
        });
    }, []);

    return (
        <div className={`fixed w-full left-0 top-0 z-[999] ${sticky ? "bg-white/60 text-gray-800" : "text-white"
            }`}>
            <div className="flex items-center justify-between">
                <div className="flex-none m-2 mt-3">
                    <Link to='/' className="flex w-14 md:w-20 mx-7">
                        <img src={logo} alt="TBM"></img>
                    </Link>
                </div>
                <div>
                    <div onClick={() => setNavbarBuka(!navbarBuka)} className={`z-[999]  ${navbarBuka ? "text-gray-900" : "text-gray-800"} text-3xl lg:hidden m-5`}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path className={navbarBuka ? 'hidden' : 'block'} strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                            <path className={navbarBuka ? 'block' : 'hidden'} strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                    <div className={`lg:hidden text-gray-800 absolute w-2/3 h-screen px-7 py-2 font-medium bg-green-50 rounded top-12 duration-300 ${navbarBuka ? "right-0" : "right-[-100%]"}`}>
                        <ul className='flex flex-col justify-items-start h-full gap-10 py-2 mt-16 text-xl'>
                            <Link to='/' className='px-3 hover:text-green-600'>Home</Link>
                            <Link to='/Produk' className='px-3 hover:text-green-600'>Products</Link>
                            <Link to='/kontak' className='px-3 hover:text-green-600'>Services and Support</Link>
                            <button onClick={() => window.location.href = 'https://wrkpln.greenhcm.com/'}
                                className='px-4 py-2' style={{ backgroundColor: "#fd7e14", color: "#d8d8d8", borderRadius: "40px" }}>Login</button>
                        </ul>
                    </div>
                </div>
                <div className={` ${sticky ? "lg:bg-white/0 bg-blue-gray-600" : "bg-green-500"} text-gray-800 lg:block hidden px-9 py-4 font-semibold rounded-bl-full`}>
                    <ul className="flex items-center gap-3 lg:text-lg">
                        <li><Link to='/' className="px-4">Home</Link></li>
                        <li><Link to='/Produk' className="px-4">Products</Link></li>
                        <li><Link to='/kontak' className='px-4'>Services and Support</Link></li>
                        <button onClick={() => window.location.href = 'https://wrkpln.greenhcm.com/'}
                            className='font-bold h-10 w-32 items-center' style={{ backgroundColor: "#fd7e14", color: "#d8d8d8", borderRadius: "40px" }}>Login</button>
                    </ul>
                </div>
            </div>
        </div>
    )
}