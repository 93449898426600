import React from 'react'
import { Kontak } from '../page/contactpage'
import { Bagian } from '../page/footerpage'

export const KontakPage = () => {
    return (
        <>
            <Kontak />
            <Bagian />
        </>
    )
}