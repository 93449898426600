import React from "react";
import icon3 from "../../asset/portfolio/icons/Icon1.png";
import icon4 from "../../asset/portfolio/icons/Icon2.png";
import icon5 from "../../asset/portfolio/icons/Icon3.png";

export const Problem = () => {
    const text1 = {
        color: '#4d4d4d',
    }
    const text2 = {
        color: '#333333',
    }

    return (
        <>
            <div className="md:pb-0 pb-10 -mt-20">
                <div className="w-full h-full" style={{ backgroundColor: "#ebf1f1" }}>
                    <div className="container mx-auto px-4 justify-items-center">
                        <div className="flex flex-wrap">
                            <div className="md:pt-10 pt-6 md:w-4/12 px-4 text-center items-start"
                                data-aos="flip-up" data-aos-easing="linear" data-aos-duration="1000">
                                <div className="relative flex flex-col min-w-0 break-words bg-gray-300 w-full mb-8 shadow-md rounded-lg">
                                    <div className="px-4 py-5 flex-auto font-Roboto">
                                        <div className="p-3 text-center inline-flex hover:animate-bounce items-center justify-center w-12 h-12 mb-5 shadow rounded-full" style={{ backgroundColor: "orange" }}>
                                            <img alt="WrkPln" src={icon5} className="lg:w-full w-9/12 align-middle" />
                                        </div>
                                        <h6 className="lg:text-lg text-sm font-semibold leading-relaxed" style={text2}>Time consuming process</h6>
                                        <p className="lg:text-base text-xs leading-relaxed mt-4 mb-2" style={text1}>
                                            Companies spend an excess amount of time tracking their travelling employees' Journey throughout the day</p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full md:w-4/12 px-4 text-center"
                                data-aos="flip-up" data-aos-easing="linear" data-aos-duration="2000">
                                <div className="relative flex flex-col min-w-0 break-words bg-gray-300 w-full mb-8 shadow-md rounded-lg">
                                    <div className="px-4 py-5 flex-auto font-Roboto">
                                        <div className="p-3 text-center inline-flex hover:animate-bounce items-center justify-center w-12 h-12 mb-5 shadow rounded-full" style={{ backgroundColor: "orange" }}>
                                            <img alt="WrkPln" src={icon4} className="lg:w-full w-9/12 align-middle" />
                                        </div>
                                        <h6 className="lg:text-lg text-sm font-semibold leading-relaxed" style={text2}>Manage tasks in<span className="text-orange-500 hover:text-green-600 font-Roboto"> Real Time</span></h6>
                                        <p className="lg:text-base text-xs leading-relaxed mt-4 mb-2" style={text1}>Companies often do not record and track their employees' assigned and completed tasks</p>
                                    </div>
                                </div>
                            </div>
                            <div className="md:pt-6 pt-1 w-full md:w-4/12 px-4 text-center"
                                data-aos="flip-up" data-aos-easing="linear" data-aos-duration="1000">
                                <div className="relative flex flex-col min-w-0 break-words bg-gray-300 w-full mb-8 shadow-md rounded-lg">
                                    <div className="px-4 py-5 flex-auto font-Roboto">
                                        <div className="p-3 text-center inline-flex hover:animate-bounce items-center justify-center w-12 h-12 mb-5 shadow rounded-full" style={{ backgroundColor: "orange" }}>
                                            <img alt="WrkPln" src={icon3} className="lg:w-full w-9/12 align-middle" />
                                        </div>
                                        <h6 className="lg:text-lg text-sm font-semibold leading-relaxed" style={text2}>What make us <span className="text-orange-500 hover:text-green-600 font-Roboto">Different</span></h6>
                                        <p className="lg:text-base text-xs leading-relaxed mt-4 mb-2" style={text1}>With affordable prices and carefully designed products, <span className="text-green-500 text-lg hover:text-orange-600 font-semibold">WrkPln</span> provides clients with a platform to activate real-time productivity monitoring systems</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <svg
                        className="relative bottom-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="fill-current text-white"
                            points="2560 0 2560 100 0 100"
                        ></polygon>
                    </svg>
                </div>
            </div >
        </>
    )
}