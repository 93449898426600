import React from "react";
import wrk from "../../asset/img/bg/logo.png";
import { Link } from 'react-router-dom';


export const Bagian = () => {

    return (
        <footer className="pb-0" style={{ backgroundColor: "#191919" }}>
            <div className="grid grid-cols-2 gap-8 px-6 py-6 md:grid-cols-3">
                <div className="mb-3 mt-6 lg:mt-14">
                    <h2 className="lg:text-base text-sm font-semibold mx-2"
                        style={{ color: "#d8d8d8", fontFamily: "Roboto" }}>About Wrkpln</h2>
                    <hr className="h-px rounded my-4 w-28 bg-gray-400 border-0"></hr>
                    <ul className="lg:text-sm text-xs mt-3"
                        style={{ color: "#6c757d", fontFamily: "sans-serif" }}>
                        <li className="mb-4">
                            <Link to="/Company" className=" hover:underline">
                                Company Information
                            </Link>
                        </li>
                        <li className="mb-4">
                            <a href="#" className="hover:underline">
                                User Guide
                            </a>
                        </li>
                        <li className="mb-4">
                            <Link to="/Terms" className="hover:underline">
                                Terms &amp; Conditions
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="mb-3 mt-6 lg:mt-14">
                    <h2 className="lg:text-base text-sm mx-2 font-semibold"
                        style={{ color: "#d8d8d8", fontFamily: "Roboto" }}>Contact Us</h2>
                    <hr className="h-px rounded my-4 w-24 bg-gray-400 border-0"></hr>
                    <ul className="lg:text-sm text-xs mt-3"
                        style={{ color: "#6c757d", fontFamily: "sans-serif" }}>
                        <li className="mb-4">
                            <a href="mailto:support@greenhcm.com" className="hover:underline hover:text-green-600">
                                Support@greenhcm.com
                            </a>
                        </li>
                        <li className="mb-4">
                            <a href="tel:+6285591299582" className="hover:underline">
                                +62-856-9129-9582
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="mb-3 mt-3 lg:mt-2">
                    <img src={wrk} alt="WrkPln" className="mb-4 h-9 w-auto lg:h-12" />
                    <h1 className="lg:text-sm text-xs mt-3"
                        style={{ color: "#6c757d", fontFamily: "sans-serif" }}>
                        WrkPlan is the best cloud-based
                        <span className="hover:text-gray-50 cursor-pointer"> Human Resource Information System &amp; Sales Force Management</span> that can help your company make good decisions, improve efficiency and accelerate your business growth.
                    </h1>
                </div>
            </div>
            <div className="px-4 py-6 bg-gray-700 md:flex md:items-center md:justify-between">
                <span className="lg:text-sm text-xs sm:text-center"
                    style={{ color: "#d8d8d8", fontFamily: "sans-serif" }}>
                    © 2023 <Link to="/Company">PT Teknologi Bangsa Mendunia</Link>. All Rights Reserved.
                </span>
                <div className="flex mt-4 space-x-6 sm:justify-center md:mt-0">
                    <a
                        href="https://web.facebook.com/Greenhcm-830695027073804/"
                        className="text-white hover:text-gray-900"
                    >
                        <svg
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                        >
                            <path
                                fillRule="evenodd"
                                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                                clipRule="evenodd"
                            />
                        </svg>
                        <span className="sr-only">Facebook page</span>
                    </a>
                    <a
                        href="https://www.instagram.com/greenhcm"
                        className="text-white hover:text-gray-900"
                    >
                        <svg
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                        >
                            <path
                                fillRule="evenodd"
                                d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                                clipRule="evenodd"
                            />
                        </svg>
                        <span className="sr-only">Instagram page</span>
                    </a>
                    <a
                        href="https://www.linkedin.com/company/greenhcm"
                        className="text-white hover:text-gray-900"
                    >
                        <svg
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                            aria-hidden="true">
                            <path
                                fillRule="evenodd"
                                d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 8c0 .557-.447 1.008-1 1.008s-1-.45-1-1.008c0-.557.447-1.008 1-1.008s1 .452 1 1.008zm0 2h-2v6h2v-6zm3 0h-2v6h2v-2.861c0-1.722 2.002-1.881 2.002 0v2.861h1.998v-3.359c0-3.284-3.128-3.164-4-1.548v-1.093z"
                                clipRule="evenodd"
                            />
                        </svg>
                        <span className="sr-only">Twitter page</span>
                    </a>
                </div>
            </div>
        </footer>
    )
}